<template>
  <div>
    <h1 class="mb-4">{{jobDefinition.titel}}</h1>

    <v-expansion-panels
      multiple
    >
      <draggable v-model="form.inputData.interview" @start="drag=true" @end="drag=false" handle=".handle">
        <v-expansion-panel
          v-for="(groupItem,groupKey) in form.inputData.interview"
          :key="groupItem.key"
        >
          <v-expansion-panel-header>
            <v-icon
              small
              class="page__grab-icon handle"
              style="cursor:pointer"
              title="Verschieben"
            >
              {{ icons.mdiArrowAll }}
            </v-icon>
            <v-row
            <v-col
              cols="11"
              sm="11"
            >
              <v-text-field
                v-model="groupItem.gruppe"
                outlined
                dense
                class="no-pointer-events"
                hide-details="auto"
                label="Gruppe"
                @click.stop
              ></v-text-field>
            </v-col>
            <v-col
              cols="1"
              sm="1"
            >
              <v-icon
                small
                class="page__grab-icon"
                style="cursor:pointer"
                title="Löschen"
                @click="removeGroupItem(groupKey)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <draggable v-model="groupItem.fragen" @start="drag=true" @end="drag=false" handle=".handle">
              <v-row v-for="(item, itemKey) in groupItem.fragen" :key="itemKey">
                <v-col
                  cols="1"
                  sm="1"
                  class="text-right"
                >
                  <v-icon
                    small
                    class="page__grab-icon handle"
                    style="cursor:pointer"
                    title="Verschieben"
                  >
                    {{ icons.mdiArrowAll }}
                  </v-icon>
                </v-col>
                <v-col
                  cols="10"
                  sm="10"
                >
                  <v-select
                    v-model="item.idThInterviewFrage"
                    label="Frage"
                    outlined
                    dense
                    hide-details="auto"
                    :items="interviewQuestionsComputed(groupKey,itemKey)"
                    item-text="displayNameInterviewQuestion"
                    item-value="idThInterviewFrage"
                    :value-comparator="(a, b) => Math.round(a) == Math.round(b)"
                  ></v-select>
                </v-col>
                <v-col
                  cols="1"
                  sm="1"
                >
                  <v-icon
                    small
                    class="page__grab-icon"
                    style="cursor:pointer"
                    title="Löschen"
                    @click="removeQuestionItem(groupKey,itemKey)"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-col>
              </v-row>
            </draggable>
            <v-btn
              color="primary"
              class="mt-4"
              @click="addNewQuestionItem(groupItem.fragen)"
            >
              Neue Frage
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </draggable>
    </v-expansion-panels>


      <v-btn
        color="primary"
        class="mt-4 mr-4"
        @click="addNewGroupItem"
      >
        Neue Gruppe
      </v-btn>


    <v-btn
      color="secondary"
      class="mt-4"
      @click="onSubmit"
    >
      Speichern
    </v-btn>
  </div>
</template>

<script>
import {getCurrentInstance, nextTick, onMounted, ref, computed} from '@vue/composition-api'
import axios from "@axios";
import router from "@/router";
import {
  mdiAlphaFBoxOutline, mdiAlphaTBoxOutline, mdiArrowAll,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiMinus,
  mdiSwapHorizontalBold
} from "@mdi/js";
import Draggable from 'vuedraggable';

export default {
  components: {
    Draggable
  },
  setup(){

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    const form = ref({});
    form.value = {
      url: '/api/jobDefinition/',
      aktion: 'fetchJobDefinitionInterviewQuestions',
      inputData: {
        idThJobDefinition: router.currentRoute.params.idThJobDefinition,
        interview: []
      }
    };

    const jobDefinition = ref({});

    const interviewQuestions = ref([]);
    const interviewQuestionsComputed = ((gk, ik) => {
      let usedIds = [];
      form.value.inputData.interview.forEach((groupItem, groupKey) => {
        groupItem.fragen.forEach((item, itemKey) => {
          if(gk != groupKey || ik != itemKey){
            usedIds.push(item.idThInterviewFrage);
          }
        })
      })
      let result = [];
      interviewQuestions.value.forEach((item, key) => {
        let questionIsUsed = false;
        usedIds.forEach(usedId => {
          if(item.idThInterviewFrage == usedId){
            questionIsUsed = true;
          }
        })
        if(!questionIsUsed){
          result.push(item);
        }
      })
      return result;
    })

    const defaultGroupItem = {
      gruppe: '',
      sortierungGruppe: null,
      fragen: []
    }

    const defaultQuestionItem = {
      idThInterviewFrage: 0,
      displayNameInterviewQuestion: 'Bitte wählen',
    }

    const addNewGroupItem = () => {
      let newItem = JSON.parse(JSON.stringify(defaultGroupItem));
      newItem.gruppe = 'Gruppe';
      newItem.sortierungGruppe = (parseInt(form.value.inputData.interview.length) + 1);
      form.value.inputData.interview.push(newItem)
    }

    const removeGroupItem = (groupKey) => {
      form.value.inputData.interview = form.value.inputData.interview.filter((x, i) => i != groupKey)
    }

    const addNewQuestionItem = (groupItem) => {
      let newItem = JSON.parse(JSON.stringify(defaultQuestionItem));
      newItem.idThInterviewFrage = Math.random();
      newItem.displayNameInterviewQuestion = 'New Question';
      groupItem.push(newItem)
    }

    const removeQuestionItem = (groupKey, itemKey) => {
      form.value.inputData.interview[groupKey].fragen = form.value.inputData.interview[groupKey].fragen.filter((x, i) => i != itemKey)
    }

    onMounted(() => {
      axios.post(form.value.url, {aktion: form.value.aktion, inputData: form.value.inputData})
        .then((res) => {
          if(res.data.valid === true){
            interviewQuestions.value = res.data.interviewQuestions;
            form.value.inputData.interview = res.data.interview;
            jobDefinition.value = res.data.jobDefinition;
          }
        })
        .catch((error) => {
          console.log(error);
        })
    })

    const onSubmit = () => {
      axios.post(form.value.url, {
        aktion: "saveJobDefinitionInterviewQuestions",
        inputData: form.value.inputData
      })
        .then((res) => {
          if(res.data.valid === true){
            router.push('/jobDefinition',()=>{
              vm.$toastr.s('Erfolgreich', 'Daten gespeichert!');
            })

          }
        })
        .catch((error) => {
          console.log(error);
        })
    }

    return {
      form,
      jobDefinition,
      addNewGroupItem,
      removeGroupItem,
      addNewQuestionItem,
      removeQuestionItem,
      interviewQuestions,
      interviewQuestionsComputed,
      onSubmit,
      icons: {
        mdiPlus,
        mdiMinus,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiEyeOutline,
        mdiAlphaFBoxOutline,
        mdiAlphaTBoxOutline,
        mdiArrowAll,
        mdiSwapHorizontalBold,
      },
    }
  },
}
</script>

<style>
.v-item-group > div {
  width: 100%;
}
</style>
